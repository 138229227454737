/* eslint-disable camelcase */
import React from 'react';
import Tippy from '@tippyjs/react';
import AuditTableChangeDetails from '../auditTableChangeDetails/auditTableChangeDetails';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { MRT_ColumnDef } from 'mantine-react-table';
import { RequestTypeForSyncHistoryRecord, SyncStatusStates, OutOfChargeStatus } from 'src/enums/enum';
import { AuditTableDetails } from 'src/types/table';
import { Icon } from '@clearchannelinternational/ecooh-system';
import { OasisOrAidaFields } from 'src/contexts/appContext';
import { TFunction } from 'i18next';
import { UserInfo } from 'src/types/apiModels';
import { AuditDetailsTableType } from '../../../types/table';
import { getTooltipText } from 'src/pages/tocPocTabSidebarTab/tocPocDetailsTable/tocPocDetailsTableConfig';
import {
  getFormattedDateToBeDisplayed,
  DATE_FORMAT,
  DATE_FORMAT_TO_BE_DISPLAYED,
  DATE_TIME_INPUT_FORMAT,
  DATE_TIME_OUTPUT_FORMAT,
} from 'src/utils/dateUtils';
import { renderDay } from 'src/components/searchField/searchField';

export const generateCellValue = (
  changeDetails: AuditTableDetails,
  value: string,
  fieldName: string,
  maximumVisibleCharacters: number
) => {
  switch (fieldName) {
    case 'Comments':
      if (shouldReturnNAForPOCData(changeDetails)) return 'NA';
      return formatComments(value, maximumVisibleCharacters);

    case 'Start Date':
    case 'End Date':
      return formatDate(value);

    case 'Dismantle Site':
      if (shouldReturnNAForTOCData(changeDetails)) return 'NA';
      return formatDismantleSite(value);

    case 'Reason':
      if (shouldReturnNAForPOCData(changeDetails)) return 'NA';
      return value;

    default:
      return value;
  }
};

function shouldReturnNAForTOCData(changeDetails: AuditTableDetails): boolean {
  return changeDetails.outOfChargeType === OutOfChargeStatus.TOC;
}

function shouldReturnNAForPOCData(changeDetails: AuditTableDetails): boolean {
  return changeDetails.outOfChargeType === OutOfChargeStatus.POC;
}

function formatComments(value: string, maximumVisibleCharacters: number): JSX.Element | string {
  if (!value) return '';

  if (value.length > maximumVisibleCharacters) {
    const truncatedText = `${value.slice(0, maximumVisibleCharacters)}.....`;
    return (
      <div className="comments-info-container">
        {truncatedText}
        <div className="comments-info">
          <Icon iconName="information" size="small" tooltip={value} tooltipIcon="information" />
        </div>
      </div>
    );
  } else {
    return value;
  }
}

function formatDate(value: string): string {
  return value ? getFormattedDateToBeDisplayed(value, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED) : '';
}

function formatDismantleSite(value: boolean | string): string {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  } else {
    return value;
  }
}

function getDigitalUnitIdsWithPanelNumbers(cell) {
  const digitalUnitIds = cell.cell.getValue() as string[];
  const panelNumbers = cell.row.original.panelNo;
  const digitalUnitIdsWithPanelNo = digitalUnitIds
    ?.map((digitalUnitId, index) => `${digitalUnitId} (${panelNumbers?.[index]})`)
    .join('\n');
  return digitalUnitIdsWithPanelNo;
}

function modifiedFilterChoices() {
  return ['Yes', 'No', 'NA'];
}

const getUsernames = (userDetails: UserInfo[]) => {
  return userDetails.map((user) => `${user.lastName}, ${user.firstName}`);
};

export const getAuditViewTableColumns = (
  fieldsConfig: OasisOrAidaFields,
  translate: TFunction<'translation', undefined>,
  auditTableData: AuditTableDetails[],
  userDetails: UserInfo[]
): MRT_ColumnDef<AuditDetailsTableType>[] => {
  return [
    {
      accessorKey: 'siteKey',
      header: fieldsConfig.siteKey && fieldsConfig.siteKey.label,
      size: 150,
      filterVariant: 'text',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      filterFn: 'fuzzy',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
      Cell: (cell) => {
        const isDoubleSided = cell.row.original.isDoubleSided;
        const siteKey = cell.cell.getValue();
        const panelids = cell.row.original.panelId;

        return (
          <div className="tooltip-column">
            <>{siteKey}</>
            <Tippy
              appendTo={document.body}
              maxWidth="200rem"
              placement="top"
              interactive
              arrow={true}
              content={
                <div className="google-maps-content">
                  <Icon fontSize="1rem" iconName="information" />
                  <div className="street-view-tooltip-text">{getTooltipText(panelids)}</div>
                </div>
              }
            >
              <div>{isDoubleSided && <Icon iconName="book" />}</div>
            </Tippy>
          </div>
        );
      },
    },
    {
      accessorKey: 'digitalUnitId',
      header: `${translate('table:digitalUnitIdWithPanelNo')}`,
      size: 132,
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      filterFn: 'fuzzy',
      filterVariant: 'text',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
      Cell: (cell) => {
        const digitalUnitIdsWithPanelNo = getDigitalUnitIdsWithPanelNumbers(cell);
        return <div className="new-line">{digitalUnitIdsWithPanelNo}</div>;
      },
    },
    {
      accessorKey: 'frameIds',
      header: `${translate('table:broadsignFrameId')}(s)`,
      size: 155,
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      filterFn: 'fuzzy',
      filterVariant: 'text',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
      Cell: (cell) => {
        const broadsignFrameIds = cell.row.original.frameIds;
        return broadsignFrameIds.join(', ');
      },
    },
    {
      accessorKey: 'commercialFrameId',
      header: 'Commercial Frame ID(s)',
      size: 165,
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      filterFn: 'fuzzy',
      filterVariant: 'text',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
      Cell: (cell) => {
        const panelIds = cell.cell.getValue() as string[];
        return panelIds.join(', ');
      },
    },
    {
      accessorKey: 'panelId',
      header: 'Panel ID(s)',
      size: 135,
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      filterFn: 'fuzzy',
      filterVariant: 'text',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
      Cell: (cell) => {
        const panelIds = cell.cell.getValue() as string[];
        return panelIds.join(', ');
      },
    },
    {
      accessorKey: 'auditEventType',
      header: 'Audit event type',
      size: 152,
      filterFn: 'fuzzy',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      filterVariant: 'text',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
      accessorFn: (originalRow) =>
        RequestTypeForSyncHistoryRecord[originalRow.auditEventType as unknown as RequestTypeForSyncHistoryRecord],
    },
    {
      accessorKey: 'startDateModified',
      accessorFn: (originalRow) => (originalRow.startDateModified ? 'Yes' : 'No'),
      header: translate('table:startDateModified'),
      filterVariant: 'multi-select',
      enableColumnFilterModes: false,
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      mantineFilterMultiSelectProps: {
        data: ['Yes', 'No', 'NA'] as string[] & string,
      },
      size: 145,
    },
    {
      accessorKey: 'endDateModified',
      accessorFn: (originalRow) => (!originalRow.outOfChargeType ? 'NA' : originalRow.endDateModified ? 'Yes' : 'No'),
      header: translate('table:endDateModified'),
      filterVariant: 'multi-select',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      enableColumnFilterModes: false,
      mantineFilterMultiSelectProps: {
        data: modifiedFilterChoices() as string[] & string,
      },
      size: 145,
    },
    {
      accessorKey: 'reasonModified',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      accessorFn: (originalRow) => (!originalRow.outOfChargeType ? 'NA' : originalRow.reasonModified ? 'Yes' : 'No'),
      header: translate('table:reasonModified'),
      filterVariant: 'multi-select',
      enableColumnFilterModes: false,
      mantineFilterMultiSelectProps: {
        data: modifiedFilterChoices() as string[] & string,
      },
      size: 145,
    },
    {
      accessorKey: 'changeDetails',
      header: translate('table:changeDetails'),
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      enableSorting: false,
      size: 115,
      Cell: (cell) => (
        <Tippy
          appendTo={document.body}
          maxWidth="200rem"
          placement="top"
          content={
            <>
              {' '}
              <AuditTableChangeDetails
                changeDetailsIndex={cell.row.id}
                auditTableData={auditTableData && auditTableData[0] ? auditTableData : []}
              />
            </>
          }
          interactive
        >
          <div className="audit-change-details-icon">
            <Icon iconName="chat" />
          </div>
        </Tippy>
      ),
    },
    // {
    //   accessorKey: 'slotId',
    //   header: fieldsConfig.slotId && fieldsConfig.slotId.label,
    //   Cell: (cell) => {
    //     return <>{cell.cell.getValue() as string[]}</>;
    //   },
    //   size: 100,
    // },
    {
      accessorKey: 'outOfChargeType',
      header: translate('table:outOfChargeType'),
      size: 160,
      filterVariant: 'select',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      enableColumnFilterModes: false,
      mantineFilterSelectProps: {
        data: ['TOC', 'POC'] as string[] & string,
      },
    },
    {
      accessorFn: (row) => new Date(row.actionDate),
      id: 'actionDate',
      header: translate('table:actionDate'),
      size: 305,
      Cell: ({ cell }) => {
        const actionDate = cell.row.original.actionDate;
        return getFormattedDateToBeDisplayed(actionDate, DATE_TIME_INPUT_FORMAT, DATE_TIME_OUTPUT_FORMAT);
      },
      filterFn: 'lessThan',
      columnFilterModeOptions: ['greaterThan', 'lessThan', 'between'],
      Filter: (filterProp) => {
        const type = filterProp.column.columnDef._filterFn === 'between' ? 'range' : 'default';
        return (
          <DatePickerInput
            type={type}
            clearable={true}
            style={
              {
                // width: '60rem',
                // marginRight: '0.5rem',
              }
            }
            renderDay={renderDay}
            placeholder="Filter by Action Date"
            onChange={(newValue: Date | DatesRangeValue) => {
              filterProp.column.setFilterValue(newValue);
            }}
          />
        );
      },
    },
    {
      accessorKey: 'updatedBy',
      header: 'Updated by',
      size: 150,
      accessorFn: (originalRow) => {
        const updateByUser: any = originalRow.updatedBy;
        const userName = `${updateByUser.lastName}, ${updateByUser.firstName}`;
        return !updateByUser.lastName || !updateByUser.firstName ? JSON.stringify(updateByUser) : userName;
      },
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      enableColumnFilterModes: false,
      filterVariant: 'multi-select',
      mantineFilterMultiSelectProps: {
        data: getUsernames(userDetails) as string[] & string,
      },
      Cell: (cellProps) => {
        const updateByUser: any = cellProps.row.original.updatedBy;
        const userName = `${updateByUser.lastName}, ${updateByUser.firstName}`;
        return !updateByUser.lastName || !updateByUser.firstName ? JSON.stringify(updateByUser) : userName;
      },
    },
    {
      accessorKey: 'broadSignSyncStatus',
      enableColumnFilter: true,
      accessorFn: (originalRow) => {
        switch (originalRow.broadSignSyncStatus) {
          case SyncStatusStates.SUCCESS:
            return 'Success';
          case SyncStatusStates.FAILURE:
            return 'Failed';
          default:
            return 'NA';
        }
      },
      header: translate('table:broadsignSyncStatus'),
      filterVariant: 'multi-select',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      enableColumnFilterModes: false,
      mantineFilterMultiSelectProps: {
        data: ['Success', 'Failed', 'NA'],
      },
      Cell: (cellProps) => {
        const cellValue = cellProps.cell.getValue();
        switch (cellValue) {
          case 'Success':
            return <Icon iconName="checkmark--filled" />;
          case 'Failed':
            return <img src={'/remove.png'} className="remove-icon" alt="img" />;
          default:
            return <span style={{ marginLeft: '0.8rem' }}>NA</span>;
        }
      },
      size: 150,
    },
    {
      accessorKey: 'oasisSyncStatus',
      enableColumnFilter: true,
      accessorFn: (originalRow) => {
        switch (originalRow.oasisSyncStatus) {
          case SyncStatusStates.SUCCESS:
            return 'Success';
          case SyncStatusStates.FAILURE:
            return 'Failed';
          default:
            return 'NA';
        }
      },
      header: translate('table:oasisSyncStatus'),
      filterVariant: 'multi-select',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      enableColumnFilterModes: false,
      mantineFilterMultiSelectProps: {
        data: ['Success', 'Failed', 'NA'],
      },
      Cell: (cellProps) => {
        const cellValue = cellProps.cell.getValue();
        switch (cellValue) {
          case 'Success':
            return <Icon iconName="checkmark--filled" />;
          case 'Failed':
            return <img src={'/remove.png'} className="remove-icon" alt="img" />;
          default:
            return <span style={{ marginLeft: '0.8rem' }}>NA</span>;
        }
      },
      size: 150,
    },
    // {
    //   accessorKey: 'aidaSyncStatus',
    //   header: t('table:aidaSyncStatus'),
    //   Header: (filterProp) => (
    //     <div className="column-header">
    //       {filterProp.column.columnDef.header}
    //     </div>
    //   ),
    //   enableColumnFilterModes: false,
    //   enableColumnFilter: false,
    //   enableSorting: false,
    //   Cell: (cell) => {
    //     return <>{cell.cell.getValue() ? 'Yes' : 'No'}</>;
    //   },
    //   size: 95,
    // },
  ];
};
